<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <div class="button_list">
                    <sn-button :snButton="snButton" @handleChange="handleChange"></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form ref="formdata" :inline="true" :model="formdata.saveJson" class="demo-form-inline form_row" size="small" label-width="200px">
                <el-form-item label="预警编号" prop="NUMBER">
                    <el-input v-model="formdata.saveJson.NUMBER" :maxlength="50" placeholder="请输入预警编号" :disabled="!isEdit"></el-input>
                </el-form-item>
                <el-form-item label="发布时间" prop="PRETIMESIGN">
                    <el-date-picker v-model="formdata.saveJson.PRETIMESIGN" :disabled="!isEdit" value-format="yyyy-mm-dd" placeholder="请选择发布时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="预警信号名" prop="TITLE">
                    <el-input v-model="formdata.saveJson.TITLE" :maxlength="50" placeholder="请输入预警信号名" :disabled="!isEdit"></el-input>
                </el-form-item>
                <el-form-item label="发布内容(同cont)" class="from-item-block">
                    <el-input v-model="formdata.saveJson.CONTENSIGN" placeholder="请输入发布内容(同cont)" type="textarea" :autosize="{ minRows: 6 }" :maxlength="500" show-word-limit :disabled="!isEdit">
                    </el-input>
                </el-form-item>
                <el-form-item label="发布站点" prop="STATION">
                    <el-input v-model="formdata.saveJson.STATION" :maxlength="50" placeholder="请输入发布站点" :disabled="!isEdit"></el-input>
                </el-form-item>
                <el-form-item label="发布人员" prop="SUBSCRIBER">
                    <el-input v-model="formdata.saveJson.SUBSCRIBER" :maxlength="50" placeholder="请输入发布人员" :disabled="!isEdit"></el-input>
                </el-form-item>
                <el-form-item label="防御指南" class="from-item-block">
                    <el-input v-model="formdata.saveJson.FY" placeholder="请输入防御指南" type="textarea" :autosize="{ minRows: 6 }" :maxlength="500" show-word-limit :disabled="!isEdit">
                    </el-input>
                </el-form-item>
                <el-form-item label="预警代码名称" prop="CODENAME">
                    <el-input v-model="formdata.saveJson.CODENAME" :maxlength="50" placeholder="请输入预警代码名称" :disabled="!isEdit"></el-input>
                </el-form-item>
                <el-form-item label="预警解除时间" prop="JCTIME">
                    <el-date-picker v-model="formdata.saveJson.JCTIME" :disabled="!isEdit" value-format="yyyy-mm-dd" placeholder="请选择预警解除时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="行政区" prop="DISTRICT">
                    <el-input v-model="formdata.saveJson.DISTRICT" :maxlength="50" placeholder="请输入行政区" :disabled="!isEdit"></el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import snButton from '../../../edit-button.js';
import infoMixin from '../../../info_mixin.js';
import { mapActions } from 'vuex';
export default {
    name: "servicedata_qx_form",
    components: {
    },
    mixins: [infoMixin],
    data() {
        return {
            notNeedFile: true,
            snButton: snButton,
            // 根据承诺事项来源类型不同掉服务获取承诺事项来源列表
            cnwcsj: false,
            cnwcsj_date: '',
            formdata: {
                saveJson: {
                    NUMBER: '',
                    PRETIMESIGN: '',
                    TITLE: '',
                    CONTENSIGN: '',
                    STATION: '',
                    SUBSCRIBER: '',
                    FY: '',
                    CODENAME: '',
                    JCTIME: '',
                    DISTRICT: '',
                },
                heritageId: '',
                itemID: '',
                userName: '',
                userId: ''
            }
        };
    },
    computed: {
    },
    watch: {
        'formdata.saveJson.cnsxlylx': function() {
        },
    },
    mounted() {
    },
    methods: {
        ...mapActions([
        ]),
    }
};
</script>
<style lang="scss" scoped>
.basic_data_edit {
    // border:1px solid red;
}
</style>
